
import './App.css';

function App() {
  return (
      <body className="is-preload">

      <div id="wrapper" className="divided">

        <section
            className="spotlight style1 orient-left content-align-left image-position-center onscroll-image-fade-in">
          <div className="content">
            <h1>TEGRAT AUTO</h1>
            <p className="major">Rental Program</p>
            <p>Put your plans in motion with the perfect deal for every vehicle need.
Text or call 888-458-7398 with your vehicle needs for booking assistance.
</p>
            <ul className="actions stacked">
              <li><a href="https://fante-engineering-llc.fleetwire.io/rental" className="button big wide smooth-scroll-middle">Book Now</a></li>
            </ul>
          </div>
          <div className="image">
            <img src="images/logo_big_new.jpg" alt=""/>
          </div>
        </section>

        <section
            className="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in"
            id="first">
          <div className="content">
            <h2>Short Term Basic Economy Rental          </h2>
            <p className='major'>Got a breakdown? In town for travel?</p>
            <table>
              <tbody>
              <tr>
                <td>Tier</td>
                <td>Day</td>
                <td>Week</td>
                <td>Month</td></tr>
              <tr>
                <td>Basic Economy</td>
                <td>$50</td>
                <td>$200</td>
                <td>$600</td>
              </tr>
              </tbody>
            </table>
            <p>Contact location manager to ask about our one way rental.</p>
            <ul className="actions stacked">
              <li><a href="https://www.instagram.com/tegratauto/" className="button">Our cars on Instagram</a></li>
            </ul>
          </div>
          <div className="image">
            <img src="images/spotlight01.jpg" alt=""/>
          </div>
        </section>

        <section
            className="spotlight style1 orient-left content-align-left image-position-center onscroll-image-fade-in">
          <div className="content">
            <h2>Short Term Economy Plus Rental</h2>
            <p>We pick you up from the airport!</p>
            <ul> {/* Start of unordered list for bullet points */}
              <li>Apple CarPlay included.</li>
              <li>Back up camera guaranteed.</li>
              <li>2017 or newer model.</li>
              <li>Concierge service.</li>
              <li>Drop-off and secondary insurance included.</li>
            </ul> {/* End of unordered list */}
            <ul className="actions stacked">
              <li><a href="https://www.yelp.com/biz/tegrat-vehicle-rental-west-lafayette" className="button">Learn more on Yelp</a></li>
            </ul>
            <table>
              <tbody>
              <tr>
                <td>Tier</td>
                <td>Day</td>
                <td>Week</td>
                <td>Month</td></tr>
              <tr>
                <td>Premium</td>
                <td>$60</td>
                <td>$250</td>
                <td>$750</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="image">
            <img src="images/spotlight02.jpg" alt=""/>
          </div>
        </section>

        <section
            className="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in">
          <div className="content">
            <h2>Long Term (Lease)</h2>
            <p className='major'>Internship for 3 months and need a vehicle?</p>
            <ul>
            <li>From $1000 down payment  $400/month + tax</li>
            <li>Starting at 3 months</li>
            <li>Your choice of vehicle first come first serve</li>
            <li>We cover all maintenance</li>
            <li>Breakdown: Equivalent vehicle swap within the hour guaranteed</li> 
            <li>Free roadside Assistance /</li>
            <li>Free swap to any vehicle at the location *subject to availability</li>

            </ul>
            <ul className="actions stacked">
              <li><a href="https://www.instagram.com/tegratauto/" className="button">Our cars on Instagram</a></li>
            </ul>
          </div>
          <div className="image">
            <img src="images/spotlight03.jpg" alt=""/>
          </div>
        </section>


        <section
            className="spotlight style1 orient-left content-align-left image-position-center onscroll-image-fade-in">
          <div className="content">
            <h2>Rent To Own (In House Finance) </h2>
            <p>Tegrat Rental Cars is now avalible in St.louis, MO, Pittsburgh, PA, Vancouver, BC </p>
            <ul>
              <li>Starting $1500 down</li>
              <li>$600 a month duration starting from 1 year</li>
              <li>We do not cover maintenance/ breakdown</li>
              <li>We offer our contract mechanic at almost half market price</li>
            </ul>
            <sub>Things you should know before you start:
Drivers must be at least 18 years old.
Primary insurance has to be provided by the customer.
Driver’s license must be valid in the jurisdiction.
</sub>
          </div>
          <div className="image">
            <img src="images/spotlight04.jpg" alt=""/>
          </div>
        </section>

        <section
            className="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in">
          <div className="content">
            <h2>Service Program            </h2>
            <p className='major'>We currently offer cloth seat extraction.</p>
            <p className='major'>Oil change + brake pads and rotors, battery change.</p>
            <ul>
            <li>Complimentary loaner vehicle.            </li>
            <li>Starting at 3 months</li>
            <li>Can be done mobile at your doorstep with extra charge       </li>
            </ul>
            <p className='major'>Total Engine/ Transmission service starting at $3000 + tax            </p>
            <ul>
            <li>6 month warranty            </li>
            <li>Starting at 3 months</li>
            <li>Free loaner when car is in service            </li>
            </ul>
          </div>
          <div className="image">
            <img src="images/logo_big_new.jpg" alt=""/>
          </div>
        </section>
        <section
            className="spotlight style1 orient-left content-align-left image-position-center onscroll-image-fade-in">
          <div className="content">
            <h2>Airport Luxury Pickup</h2>
            <ul>
              <li>Porsche Cayenne, BMW long wheel base limo</li>
              <li>Late night / early morning</li>
            </ul>
          </div>
          <div className="image">
            <img src="images/spotlight04.jpg" alt=""/>
          </div>
        </section>
        <section
            className="spotlight style1 orient-left content-align-left image-position-center onscroll-image-fade-in">
          <div className="content">
            <h2>North America Locations</h2>
            <p>Tegrat Rental Cars is now avalible in:</p>
            <ul className="actions stacked">
              <li><a href="https://maps.app.goo.gl/MQPkCdPLbSXNBseF9" className="button">West Lafayette, IN - 2406 Fleming Dr              </a></li>
              <li><a href="https://maps.app.goo.gl/ZM6SSR4CTuQyrc8g7" className="button">Pittsburgh, PA - 709 Collins St</a></li>
              <li><a href="https://maps.app.goo.gl/VCJgng3FULbszguQ7" className="button">Indianapolis, IN - 2834 N New Jersey St</a></li>
              <li><a href="https://maps.app.goo.gl/yG5p9TxC5Pb2JSPL9" className="button">St Louis, MO - 2860 Bartold Ave</a></li>
              <li><a href="https://www.instagram.com/tegratca/" className="button">Vancouver, CA</a></li>
            </ul>
          </div>
          <div className="image">
            <img src="images/spotlight_north_america.webp" alt=""/>
          </div>
        </section>


        <footer className="wrapper style1 align-center">
          <div className="inner">
            <ul className="icons">
              <li><a href="https://www.instagram.com/tegratauto/" className="icon brands style2 fa-instagram"><span className="label">Instagram</span></a></li>
              <li><a href="mailto:info@tegratauto.com" className="icon style2 fa-envelope"><span className="label">Email</span></a></li>
            </ul>
            <p>&copy; Tegrat Auto 2024 Design: <a href="https://html5up.net">HTML5 UP</a>.</p>
          </div>
        </footer>

      </div>

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/jquery.scrollex.min.js"></script>
      <script src="assets/js/jquery.scrolly.min.js"></script>
      <script src="assets/js/browser.min.js"></script>
      <script src="assets/js/breakpoints.min.js"></script>
      <script src="assets/js/util.js"></script>
      <script src="assets/js/main.js"></script>
<script src="https://fleetwire.io/tenant/v2/fleetwire.js"></script>
      </body>
  );
}

export default App;
